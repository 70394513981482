// @ts-nocheck
export default {
  footer: {
    copyright_text: "Everflex Plus Health | All Rights Reserved",
    pat_no_text:
        "These products are covered by Pat. No. US 11,587,687 and other pending applications.",
    about: "About",
    security_statement: "Security Statement",
    privacy_policy: "Privacy Policy",
    terms_conditions: "Terms & Conditions",
    contact: "Contact",
    support: "Support",
    faqs: "FAQs",
    patent_information_text: "Patent Information",
  },
  practice_dashboard: {
    title: "Dashboard",
    manage_user_roles: "Manage Users & Roles",
    manage_clinics: "Manage Clinics",
    active_eoc: "Active EOC's",
    active_epc_description: "Number of patients in an active episode of care",
    rtm_code: "RTM Codes Ready to Bill",
    rtm_code_description: "Total count of unbilled RTM codes in this practice.",
    registered_patient: "Registered in Last 7 Days",
    registered_patient_description:
      "Number of patients that have self-registered in the last 7 days",
    unread_message: "Unread Message",
    unread_message_description:
      "Number of unread messages in your message center",
  },
  create_practice: "Create Practice",
  edit_practice: "Edit Practice",
  include_demos: "Include Demos",
  practice_management: "Practices",
  create_practice_details: "Create Practice Details",
  practice_details: "Practice Details",
  cancel: "Cancel",
  save: "Save",
  create: "Create",
  verify: "Verify",
  publish: "Publish",
  download: "Download",
  copy: "Copy",
  create_btn_tooltip: "Please save tile changes before creating.",
  publish_btn_tooltip: "Please save tile changes before publishing.",
  practice_name_text: "Practice name",
  practice_name: {
    label: "Practice name",
    placeholder: "##COMPANYNAME##",
  },
  practice_email: {
    label: "Practice Admin Email",
    placeholder: "example@mail.com",
  },
  practice_billing_email: {
    label: "Billing Email",
    placeholder: 'example@mail.com',
    description: "Monthly invoices will be sent to this email address. If this field is not filled in, invoices will be sent to the Practice Admin's email address.",
  },
  practice_phone: {
    label: "Phone Number",
    placeholder: "Enter Number",
  },
  practice_dob: {
    label: "Date Of Birth",
    placeholder: "MM-DD-YYYY",
  },
  otp_verification: "OTP Verification",
  click_to_send: "Click to resend.",
  login: "Log in",
  login_upper: "LOG IN",
  login_email: {
    label: "Email address",
    placeholder: "Email",
  },
  login_password: {
    label: "Password",
    placeholder: "Password",
  },
  forgot_password_screen: {
    title: "Forgot Password",
    form: {
      title: "Forgot Password",
      email_label: "Email Address",
      email_placeholder: "Enter Email Address",
      submit_btn_text: "Email Password Reset Link",
      go_back_text: "Go Back",
    },
  },
  reset_password_screen: {
    title: "Reset Password",
    form: {
      title: "SET YOUR PASSWORD",
      password_field: {
        label: "Password",
        placeholder: "Password",
      },
      confirm_password_field: {
        label: "Re-Enter New Password",
        placeholder: "Re-Enter New Password",
      },
      otp_field: {
        label: "Enter Your OTP",
        placeholder: "Enter Your OTP",
      },
      submit_btn_text: "SAVE PASSWORD",
    },
  },
  login_otp: {
    label: "Enter Your OTP",
    placeholder: "Enter OTP",
  },
  time_remaining: "Time Remaining",
  seconds: "seconds",
  resend_otp: "Resend OTP",
  forgot_password: "Forgot Password?",
  link_expired: "Link Expired",
  link_expired_message: "Link expired or is invalid!",
  accept_invitation: "Accept Invitation",
  set_password_upper: "SET YOUR PASSWORD",
  access_error_title: "Oops! You don't have access to this module!",
  access_error_subtitle: "If you think you shouldn't be seeing this, please contact your Practice Administrator to check your permission settings.",
  accept_invite_password: {
    label: "Password",
    placeholder: "Password",
  },
  accept_invite_password_confirm: {
    label: "Re-Enter New Password",
    placeholder: "Password",
  },
  accept_invite_password_otp: {
    label: "Enter Your OTP",
    placeholder: "Enter OTP",
  },
  save_password_upper: "SAVE PASSWORD",
  view: "View",
  print: "Print",
  edit: "Edit",
  delete: "Delete",
  cancel_schedule: "Cancel Scheduled",
  duplicate: "Duplicate",
  view_practice_details: "View Practice Details",
  view_all: "View All",
  view_active: "View Active",
  view_archived: "View Archived",
  view_favorite: "View Favorite",
  my_content_view: "My Content",
  practice_content_view: "Practice Content",
  import_csv: "Import CSV",
  unlink_emr: "Unlink from EMR",
  practice_table_header: {
    id: "ID",
    logo: "Practice Logo",
    name: "Practice name",
    email: "Email",
    phone: "Practice Phone",
    address: "Practice Address",
    actions: "Actions",
  },
  practice_general_info_title: "Practice General Information",
  practice_contact_info_title: "Practice Contact Information",
  practice_contact_info: {
    first_name: "First Name",
    last_name: "Last Name",
    primary_phone: "Primary Contact Phone Number",
    primary_email: "Primary Contact Email",
  },
  remove: "Remove",
  pratice_import_table_header: {
    name: "Practice Name",
    email: "Email Address",
    phone: "Phone Number",
  },
  create_exercise: "Create Exercise",
  update_exercise: "Update Exercise",
  view_exercise: "View Exercise",
  exercise_management: "Exercises",
  exercise_details: "Exercise details",
  use_count_text: "Use Count:",
  no_record: "No Record Found",
  no_chats: "You have no active chats. Click the 'New Message' button and select a patient to begin a new one.",
  favorite: "Favorite",
  unfavorite: "Unfavorite",
  search_text: "Search...",
  thumb_delete: {
    title: "Delete",
    message: "Are you sure you want to delete this thumb image?",
    label1: "Yes",
    label2: "No",
  },
  image_delete: {
    title: "Delete",
    message: "Are you sure you want to delete this image?",
    label1: "Yes",
    label2: "No",
  },
  exercise_module: {
    keyword_text: "Keywords for Exercise",
    nickname_set: "Nickname successfully set",
    nicknames_text: "Global Nicknames",
    add_nickname_text: "Add A Nickname For This Exercise",
    most_common_nickname: {
      label: "Most Common Nicknames",
      tooltip: "Using this setting will display each exercise using its most common nickname across Everflex Plus. Nicknames that you have created will remain the same.",
    },
    global_nickname_modal: {
      heading: "Set Nickname Practice Wide?",
      subheading: "Would you like to apply this nickname for every provider within your practice?",
      yes: "Yes",
      no: "No",
    },
    media: {
      title: "Exercise Media",
      video: "Video",
      images: "Images",
    },
    multi_image_dropzone: {
      browse:
        "Drag and drop your file here, or <a href='#' class='browse-btn p-sm'>Browse</a>",
      allowed_file_types: "Only PNG, JPEG, and JPG files are allowed.",
    },
    lbs_text: "LBS",
    sec_text: "Sec",
    video_url_text: "Video URL",
    quick_filter_explanation: 'Filters for exercises created by superadmins, your practice admin, and you, as well as any exercises that you have favorited.',
  },
  exercise_name: {
    label: "Exercise Name",
    placeholder: "Enter Exercise Name",
  },
  exercise_nickname: {
    label: "My Nickname",
    placeholder: "Create Nickname",
    tooltip: "Adding a nickname for this exercise will change its display name for your account throughout the portal, and allow you to search for this exercise using its nickname.",
  },
  video_url: {
    label: "Video URL",
    placeholder: "Enter Video URL",
    tooltip: "Basic YouTube, Vimeo, and Wistia links are currently accepted.",
  },
  thumb: {
    label: "Thumbnail",
    text: "Drag and drop your file here, or <a href='#' class='browse-btn p-sm'>Browse</a>",
    allowed_file_types: "Only PNG, JPEG, and JPG files are allowed.",
  },
  description: {
    label: "Description of Exercise",
    placeholder: "Write Description of Exercise......",
  },
  strat_position: {
    label: "Starting Position",
    placeholder: "Enter Starting Position",
  },
  feeling: {
    label: "What should you feel",
    placeholder: "Enter What should you feel",
  },
  cpt_code: {
    label: "Default CPT Code",
    placeholder: "Enter Default CPT Code",
  },
  weight: {
    label: "Weight",
    placeholder: "Enter Default Weight",
  },
  sets: {
    label: "Sets x",
    placeholder: "",
  },
  reps: {
    label: "Reps x",
    placeholder: "",
  },
  daily: {
    label: "Times Per Day",
    placeholder: "",
  },
  hold_time: {
    label: "Hold Time (sec)",
    placeholder: "",
  },
  weekly: {
    label: "Days Per Week",
    placeholder: "",
  },
  video_type: {
    label: "Video Type",
    first_option: "Select Video Type",
  },
  created_by: {
    label: 'Created By',
  },
  side: {
    label: "Side",
    first_option: "Select Side",
  },
  body_part: {
    label: "Body Part",
    first_option: "Select Body Part",
  },
  position: {
    label: "Position",
    first_option: "Select Position",
  },
  plane: {
    label: "Plane",
    first_option: "Select Plane",
  },
  type: {
    label: "Type",
    first_option: "Select Type",
  },
  chain: {
    label: "Chain",
    first_option: "Select Chain",
  },
  reach: {
    label: "Reach",
    first_option: "Select Reach",
  },
  equipment: {
    label: "Equipment",
    first_option: "Select Equipment",
  },
  exercise_table: {
    sort: "ID",
    thumb: "Thumbnail",
    exercise_name: "Exercise name",
    body_part: "Body Part",
    type: "Type",
    created_by: "Created by",
    actions: "Actions",
  },
  archive_exercise_confirm: "Are you sure you want to archive this exercise?",
  unarchive_exercise_confirm:
    "Are you sure you want to unarchive this exercise?",
  browse_or_drag_drop_file: "Browse Or Drag and Drop your File Here",
  duplicate_exercise_confirm:
    "Are you sure you want to duplicate this Exercise?",
  drag_drop_file: "Drag and drop your file here, or",
  browse: "Browse",
  download_template: "Download Template",
  practice_import_error: "Could not import! Please retry.",
  upload_csv: "Upload CSV",
  importing: "Importing",
  import: "Import",
  extract_default_thumbnail_text: "Extract Default Thumbnail",
  custom_thumbnail_text: "Custom Thumbnail",
  extract_custom_thumbnail_text: "Extract Custom Thumbnail",
  seek_video_info_text:
    "Seek video to specific time and click on extract button to extract thumb from given time.",
  thumb_close_text: "Close",
  thumb_extract_btn_text: "Extract",
  thumb_save_btn_text: "Save",
  education: {
    list_page: {
      page_heading: "Education",
      page_title: "Educations List",
      search_text: "Search...",
      create_button_text: "Create Education",
      sort: {
        asc: "asc",
        desc: "desc",
      },
      action: {
        archive: "archive",
        unarchive: "unarchive",
        archive_confirm: "Are you sure you want to archive this education?",
        unarchive_confirm: "Are you sure you want to unarchive this education?",
        duplicate_confirm: "Are you sure you want to duplicate this education?",
      },
      table: {
        heading: {
          sort: "ID",
          title: "Title",
          short_description: "Short Description",
          created_by: "Created by",
          action_text: "Actions",
        },
      },
      no_record: "No Record Found",
    },
    create_or_update_page: {
      page_heading: "Education details",
      create_page_title: "Create Education",
      edit_page_title: "Edit Education",
      title_label: "Title of Educational Content",
      title_placeholder: "Enter Title",
      video_url_label: "Video URL",
      video_url_placeholder: "Enter URL",
      video_thumb_label: "Education Thumbnail Image",
      video_thumb_tooltip_info:
        "This image will be displayed as the cover image for this piece of educational content where cover images exist both in the patient and provider portal.",
      drag_drop_label:
        "Drag and drop your file here, or <a href='#' class='browse-btn p-sm'>Browse</a>",
      drag_drop_notice: "Maximum file size",
      thumb_allowed_file_types: "Only PNG, JPEG, and JPG files are allowed.",
      short_description_label: "Short Description",
      short_description_placeholder: "Write Short Description...",
      description_label: "Educational Content",
      add_attachment_title: "Add Attachment",
      attachment_tooltip_info: "Accepted File type: PDF",
      thumbnail_modal_title: "Crop Thumbnail",
    },
    view_page: {
      video_thumb_label: "Thumbnail for Cover Image",
      attachment_title: "Attachment",
    },
    breadcrumb: {
      list_text: "Education",
      create_text: "Create Education",
      edit_text: "Edit Education",
      view_text: "View Education",
    },
    cancel_text: "Cancel",
    save_text: "Save",
  },
  archive_practice_confirm: "Are you sure you want to archive this practice?",
  unarchive_practice_confirm:
    "Are you sure you want to unarchive this practice?",
  all: "All",
  archive_action_text: "Archive",
  re_verify_email_action_text: "Re-Verify Email",
  verify_by_phone: "Verify by Phone",
  re_verify_phone_action_text: "Re-Verify Phone",
  re_verify_qr_code: "Verify by QR Code",
  qr_code_copied: "QR code successfully copied!",
  qr_code_copy_error: "There was an issue copying the QR code, please try again.",
  unarchive_action_text: "Unarchive",
  flash_card: {
    yes_text: "Yes",
    no_text: "No",
  },

  hep: {
    table: {
      sort: "ID",
      hep_name: "HEP Template Name",
      description: "Description",
      no_of_level: "#Of Levels ",
      created_by: "Created by",
      actions: "Actions",
    },
    atleast_one_required:
      "You are required to assign at least one exercise or one piece of educational content.",
    archive_hep_confirm: "Are you sure you want to archive this HEP template?",
    unarchive_hep_confirm:
      "Are you sure you want to unarchive this HEP template?",
    duplicate_hep_confirm:
      "Are you sure you want to duplicate this HEP template?",
    create_hep: "Create HEP Template",
    update_hep: "Update HEP Template",
    view_hep: "View HEP Template",
    hep_management: "HEP Templates",
    hep_details: "HEP Template Details",
    my_content_label: "My Content",
    my_content_tooltip: "Turn this on to only see content created by you, Everflex, your practice admin, and your favorited content created by anyone.",
    default_parameters_restored: "Default parameters have been restored successfully",
    create_update_screen_tab: {
      details: "Details",
      assign_exercise: "Assign Exercise(s)",
      assign_education: "Assign Education",
    },
    view_screen_tab: {
      details: "Details",
      exercise: "Exercise(s)",
      education: "Education",
    },
    hep_name: {
      label: "HEP Name",
      placeholder: "Enter HEP Name",
    },
    hep_template_name: {
      label: "HEP Template Name",
      placeholder: "Enter HEP Template Name",
    },
    provider_note: {
      label: "Internal Provider Note",
      placeholder: "Enter Note...",
    },
    soap_note: {
      label: "SOAP Notes",
      placeholder: "Enter Note...",
    },
    description: {
      label: "Description",
      help_text:
        "The HEP Description is displayed to the patient when they first access the HEP in the patient portal.",
    },
  },
  role: {
    role_tab: "Roles List",
    user_tab: "Users List",
    list_page: {
      page_heading: "Users & Roles",
      page_title: "Roles List",
      search_text: "Search...",
      create_role_button_text: "Create Role",
      create_user_button_text: "Create User",
      sort: {
        asc: "asc",
        desc: "desc",
      },
      action: {
        archive: "archive",
        unarchive: "unarchive",
        archive_role_confirm: "Are you sure you want to archive this role?",
        unarchive_role_confirm: "Are you sure you want to unarchive this role?",
        archive_user_confirm: "Are you sure you want to archive this user?",
        archive_user_subtitle: `Archiving this provider will unassign them from all  of their patients, changing the provider field to "N/A." You will be required to assign a new provider the next time each patient's profile is accessed. Alternatively, you can reassign all patients to a new provider by selecting one below.`,
        select_provider: "Select Provider",
        unarchive_user_confirm: "Are you sure you want to unarchive this user?",
      },
      role_table_heading: {
        sort: "ID",
        role_name: "Role name",
        action_text: "Actions",
      },
      user_table_heading: {
        sort: "ID",
        profile_photo: "Profile Photo",
        first_name: "First Name",
        last_name: "Last Name",
        email: "Email Address",
        phone_number: "Phone Number",
        role: "Role",
        action_text: "Actions",
      },
      no_record: "No Record Found",
    },
    create_update_view_page: {
      role: {
        page_heading: "Roles Details",
        create_page_title: "Create Role",
        edit_page_title: "Edit Role",
        role_name_label: "Role Name",
        role_name_placeholder: "Enter Role Name",
        role_permission_label: "Role Permission",
        table_heading: {
          sort: "ID",
          permission_title: "Role Permission Title",
          all: "All",
          add: "Add",
          view: "View",
          edit: "Edit",
          delete: "Archive",
        },
      },
      user: {
        page_heading: "Users Details",
        create_page_title: "Create User",
        edit_page_title: "Edit User",
        fullname_label: "Full Name",
        first_name_label: "First Name",
        first_name_placeholder: "Enter First Name",
        last_name_label: "Last Name",
        last_name_placeholder: "Enter Last Name",
        phone_number_label: "Phone Number",
        phone_number_placeholder: "Enter Phone Number",
        email_label: "Email Address",
        email_placeholder: "Enter Email Address",
        role_permission_label: "Role Permission",
        role_permission_placeholder: "Select role permissions",
        clinic_label: "Clinic Name",
        clinic_placeholder: "Select Clinic Name",
        profile_photo: "Profile Photo",
      },
    },
    breadcrumb: {
      list_text: "Users & Roles",
      role: {
        create_text: "Create Role",
        edit_text: "Edit Role",
        view_text: "View Roles Details",
      },
      user: {
        create_text: "Create User",
        edit_text: "Edit User",
        view_text: "View Users Details",
      },
    },
    reset_password_btn_text: "Reset Password",
    cancel_btn_text: "Cancel",
    edit_btn_text: "Edit",
    save_btn_text: "Save",
  },
  action_dropdown: {
    view: "View",
    edit: "Edit",
    favorite: {
      add: "Favorite",
      remove: "Unfavorite",
    },
    archive: "Archive",
    unarchive: "Unarchive",
    activate: 'Activate',
    deactivate: "Deactivate",
  },
  filter_options: {
    key: {
      all: "all",
      active: "active",
      archived: "archived",
      favorite: "favorite",
      template: "template",
      scheduled: "scheduled",
      failed: "failed",
      successful: "successful",
      inactive: "inactive"
    },
    value: {
      all: "View All",
      active: "View Active",
      archived: "View Archived",
      favorite: "View Favorite",
      template: "View Template",
      schedule: "View Scheduled",
      failed: "View Failed",
      successful: "View Successful",
      inactive: "View Inactive",
    },
  },

  cpt_text: "CPT Code",
  side_text: "Side",
  sets_text: "Sets",
  reps_text: "Reps",
  weight_text: "Weight",
  hold_time_text: "Hold Time",
  day_per_week: "Days/Week",
  time_per_day: "Time/Day",
  education_text: "Education",
  feeling_text: "What should you feel",
  start_position_text: "Starting position",
  exercise_desc_text: "Description of exercise",
  frined_faves: "Friends' Faves",

  exercise: "Exercise",
  clear: "Clear",
  apply: "Apply",
  filter: "Filter",
  quick_filter_label: "Quick Filter",
  clear_filter: "Clear Filter",
  results: "Results",
  restore_default_params_confirm:
    "Are you sure you want to restore the default parameter for all the exercises in this HEP?",
  exercise_already_in_strategy:
    "This exercise is already being utilized in this strategy. Are you certain you wish to include it again?",
  add_strategy: "Add Strategy",
  edit_params: "Edit Parameters",
  restore_default_params: "Restore default Parameters",
  strategy: "Strategy",
  drag_drop_card: "Drag and drop your card here",
  add_level: "Add level",
  mark_as_starting_exercise: "Mark as Starting Exercise",
  times_per_day: "Times per day",
  days_per_week: "Days per week",
  remove_strategy_confirm: "Are you sure you want to remove this strategy?",
  recipients: "Recipients",
  read: "Read",
  system_notifications_module: {
    date_text: "Date",
    time_text: "Time",
    subject: "Subject",
    message: "Message",
    template_name: "Template Name",
    validation: {
      subject_required_text: "The subject field is required.",
      message_required_text: "The message field is required.",
    },
    view: {
      title: "View System Notification",
      detail_title: "System Notification Details",
    },
    list: {
      page_title: "Notifications List",
      page_heading: "System Notifications",
      create_btn: "Create New Notification",
      open_rate_tooltip:
        "Open rate is only based on the notification center and not the emails sent",
      delete_confirm:
        "Are you sure you want to delete this system notification?",
      cancel_confirm:
        "Are you sure you want to cancel this schedule notification?",
      status: {
        sent: "Sent",
        template: "Template",
        scheduled: "Scheduled",
        schedule_cancel: "Schedule Cancelled",
      },
      table: {
        sort: "ID",
        subject: "Subject",
        send_date: "Date Sent",
        created_date: "Date Created",
        schedule_date: "Schedule Date",
        notification_send_to: "Notification Send To",
        open_rate: "Open Rate",
        status: "Status",
        actions: "Actions",
      },
    },
    create_or_update: {
      create_page_title: "Create Notification",
      edit_page_title: "Edit Notification",
      create_page_heading: "System Notifications",
      edit_page_heading: "System Notifications",
      save_as_template_heading: "Save as Template",
      save_as_template_btn_text: "Save as Template",
      save_template_btn_text: "Save Template",
      save_template_field: {
        label_text: "Select Template",
        placeholder_text: "Search or Select Template Name",
      },
      date_field: {
        label_text: "Select Date",
        placeholder_text: "MM/DD/YYYY",
      },
      time_field: {
        label_text: "Select Time",
        placeholder_text: "--:-- --",
      },
      notification_send_to_field: {
        label_text: "Notification Send To",
      },
      subject_field: {
        label_text: "Subject",
        placeholder_text: "Enter Subject",
      },
      template_modal: {
        template_name_field: {
          label_text: "Your Template Name",
          placeholder_text: "Enter Template Name",
        },
        cancel_button_text: "Cancel",
        save_button_text: "Save",
      },
      phi_modal: {
        phi_compliance: {
          send_message_text: "Send Message",
          message_text: "Message contains PHI?",
          no_text: "No",
          yes_text: "Yes",
        },
        confirm_phi_compliance: {
          send_email_text: "Send Email",
          message_text:
            "You are about to send an email to users of your portal that could contain PHI information. Click below to verify that you have ensured there is no PHI content in this email.",
          cancel_text: "Cancel",
          verify_and_send_text: "Verify and Send",
        },
        confirm_send: {
          send_message_text: "Send Message",
          message_text:
            "As this email contains PHI, it will securely be delivered as a message within the Everflex portal.",
          okay_text: "Okay! Send Message",
        },
      },
      message_field_label_text: "Message",
      insert_variables_text: "Insert Variables",
      schedule_label_text: "Schedule Message (Optional)",
      preview_btn_text: "Preview",
      send_instant_btn_text: "Send",
      schedule_btn_text: "Schedule",
      save_schedule_btn_text: "Save Schedule",
    },
    breadcrumb: {
      list_text: "System Notifications",
      create_text: "Create New Notification",
      preview_text: "Preview",
      edit_text: "Edit Notification",
      view_text: "View Notification",
    },
    preview: {
      page_title: "Preview Notification",
      page_heading: "Preview",
      edit_btn_text: "Edit",
      fields: {
        date_text: "Date",
        time_text: "Time",
        subject: "Subject",
        message: "Message",
      },
      send_instant_btn_text: "Send",
      schedule_btn_text: "Schedule",
    },
  },
  system_settings_module: {
    view: {
      page_title: "System Settings",
    },
    show: {
      page_heading: "Search Functionality Weighting",
      sent_emails_header_text: "Past Email Attempts",
      sent_email_view_text: "Email",
      sent_emails_link_text: "View",
      edit_link_text: "Edit",
      create_link_text: "Create",
      system_prompts_header_text: "System Prompts",
      system_prompt_view_text: "System Prompt",
      system_prompts_link_text: "View",
      system_prompt_activate_text: "Activate",
      system_prompt_deactivate_text: "Deactivate",
      system_prompt_preview_text: "Preview",
      version_number_header_text: "Application Version Number",
      version_number_set_text: "Update",
      version_number_placeholder: "Enter version number",
    },
    edit: {
      page_heading: "Edit Search Functionality Weighting",
      cancel_edit_text: "Cancel",
      save_edit_text: "Save",
    },
    breadcrumb: {
      view_text: "System Settings",
      edit_text: "Edit Settings",
      sent_emails_text: "Past Email Attempts",
      system_prompts_text: "System Prompts",
      view_one: "View",
      edit: "Edit",
      create: "Create",
    },
    name_section: {
      title: "Names",
      search_query_and_name: "Search Query Full",
      search_query_or_name: "Search Query Portions",
      search_query_synonym_and_name: "Synonyms For Search Query Full",
      search_query_synonym_or_name: "Synonyms For Search Query Portions",
      search_query_and_explanation:
        "For the entire string of words the user inputs into the search",
      search_query_or_explanation: `For the individual words that make up the user's search query`,
      search_query_synonym_and_explanation:
        "For synonyms of the full search query the user inputs",
      search_query_synonym_or_explanation:
        "For synonyms of the individual words that make up a search query",
    },
    tags_section: {
      title: "Tags",
      tags_and_name: "Search Query Full",
      tags_or_name: "Search Query Portions",
      tags_and_explanation:
        "For the entire string of words the user inputs into the search, but matching against tags",
      tags_or_explanation: `For the individual words that make up the user's search query, but matching against the tags`,
    },
    nickname_section: {
      title: "Nicknames",
      nickname_and_name: "Search Query Full",
      nickname_or_name: "Search Query Portions",
      nickname_and_explanation:
        "For the entire string of words the user inputs into the search, but matching against nicknames",
      nickname_or_explanation: `For the individual words that make up the user's search query, but matching against the nicknames`,
    },
    use_count_section: {
      title: "Use Count",
      use_count_name: "Search Query",
      use_count_explanation:
        "Decimal value to apply boost based on the use count of the resource across the system",
    },
    description_section: {
      title: "Descriptions",
      description_and_name: "Search Query Full",
      description_or_name: "Search Query Portions",
      description_and_explanation:
        "For the entire string of words the user inputs into the search, but matching against description",
      description_or_explanation: `For the individual words that make up the user's search query, but matching against the desciption`,
    },
    created_by_section: {
      title: 'Created By',
      created_by_name: 'Search Query',
      created_by_explanation: "Matches the search query against the names of the creators of exercises",
    },
    show_sent_emails: {
      table: {
        heading: {
          sort: "ID",
          sender: "Sender",
          recipient: "Recipient",
          status: "Status",
          error_message: "Error Message",
          action_text: "Actions",
          date: "Date Sent",
        },
      },
      sent_successfully: "Sent",
      failed: "Failed",
    },
    show_system_prompts: {
      table: {
        heading: {
          sort: "ID",
          roles: "Role",
          title: "Title",
          field: "Field",
          field_type: "Field Type",
          is_acceptance: "Terms Acceptance",
          status: "Status",
          action_text: 'Actions',
        },
      },
      active: 'Active',
      inactive: 'Inactive',
      yes_acceptance: 'Yes',
      no_acceptance: 'No',
    },
    search_text: "Search By Email",
    no_record: "No Records Found",
    sort: {
      asc: "asc",
      desc: "desc",
    },
    view_sent_email: {
      practice_label: "Practice",
      sender_label: "Sender",
      recipient_label: "Recipient Email Address",
      subject_line_label: "Subject Line",
      status_label: "Status",
      error_message_label: "Error Message",
      date_label: "Date Sent",
    },
    view_system_prompt: {
      title_label: "Title",
      body_label: "Body",
      role_label: "Role Required",
      field_label: "Data Field",
      field_type_label: "Field Type",
      field_location_label: "Field Location",
      is_acceptance_label: "Terms Acceptance",
      status_label: 'Status',
      completion_label: 'Amount Completed',
    },
  },
  targeted_notifications_module: {
    date_text: "Date",
    time_text: "Time",
    subject: "Subject",
    message: "Message",
    template_name: "Template Name",
    validation: {
      subject_required_text: "The subject field is required.",
      message_required_text: "The message field is required.",
    },
    view: {
      title: "View Targeted Notification",
      detail_title: "Targeted Notification Details",
    },
    list: {
      page_title: "Notifications List",
      page_heading: "Targeted Notifications",
      create_btn: "Create New Notification",
      open_rate_tooltip:
        "Open rate is only based on the notification center and not the emails sent",
      delete_confirm:
        "Are you sure you want to delete this Targeted notification?",
      cancel_confirm:
        "Are you sure you want to cancel this schedule notification?",
      status: {
        sent: "Sent",
        template: "Template",
        scheduled: "Scheduled",
        schedule_cancel: "Schedule Cancelled",
      },
      table: {
        sort: "ID",
        subject: "Subject",
        send_date: "Date Sent",
        created_date: "Date Created",
        schedule_date: "Schedule Date",
        notification_send_to: "Notification Send To",
        open_rate: "Open Rate",
        status: "Status",
        actions: "Actions",
      },
    },
    create_or_update: {
      add_attachment_title: "Add Attachment",
      attachment_tooltip_info: "Accepted File type: PDF",
      drag_drop_label:
        "Drag and drop your file here, or <a href='#' class='browse-btn p-sm'>Browse</a>",
      drag_drop_notice: "Maximum file size",
      create_page_title: "Create Notification",
      edit_page_title: "Edit Notification",
      create_page_heading: "Targeted Notifications",
      edit_page_heading: "Targeted Notifications",
      save_as_template_heading: "Save as Template",
      save_as_template_btn_text: "Save as Template",
      save_template_btn_text: "Save Template",
      save_template_field: {
        label_text: "Select Template",
        placeholder_text: "Search or Select Template Name",
      },
      date_field: {
        label_text: "Select Date",
        placeholder_text: "MM/DD/YYYY",
      },
      time_field: {
        label_text: "Select Time",
        placeholder_text: "--:-- --",
      },
      notification_send_to_field: {
        label_text: "Notification Send To",
      },
      subject_field: {
        label_text: "Subject",
        placeholder_text: "Enter Subject",
      },
      template_modal: {
        template_name_field: {
          label_text: "Your Template Name",
          placeholder_text: "Enter Template Name",
        },
        cancel_button_text: "Cancel",
        save_button_text: "Save",
      },
      phi_modal: {
        phi_compliance: {
          send_message_text: "Send Message",
          message_text: "Message contains PHI?",
          no_text: "No",
          yes_text: "Yes",
        },
        confirm_phi_compliance: {
          send_email_text: "Send Email",
          message_text:
            "You are about to send an email to users of your portal that could contain PHI information. Click below to verify that you have ensured there is no PHI content in this email.",
          cancel_text: "Cancel",
          verify_and_send_text: "Verify and Send",
        },
        confirm_send: {
          send_message_text: "Send Message",
          message_text:
            "As this email contains PHI, it will securely be delivered as a message within the Everflex portal.",
          okay_text: "Okay! Send Message",
        },
      },
      message_field_label_text: "Message",
      insert_variables_text: "Insert Variables",
      schedule_label_text: "Schedule Message (Optional)",
      preview_btn_text: "Preview",
      send_instant_btn_text: "Send",
      schedule_btn_text: "Schedule",
      save_schedule_btn_text: "Save Schedule",
    },
    breadcrumb: {
      list_text: "Targeted Notifications",
      create_text: "Create New Notification",
      preview_text: "Preview",
      edit_text: "Edit Notification",
      view_text: "View Notification",
    },
    preview: {
      page_title: "Preview Notification",
      page_heading: "Preview",
      edit_btn_text: "Edit",
      fields: {
        date_text: "Date",
        time_text: "Time",
        subject: "Subject",
        message: "Message",
      },
      send_instant_btn_text: "Send",
      schedule_btn_text: "Schedule",
    },
    dropzone: {
      logo: {
        label_text: "Logo Image",
        drag_drop_text:
          "Drag and drop your file here, or <a href='#' class='browse-btn p-sm'>Browse</a>",
        help_text:
          "Uploaded icon will be displayed in sidebar, login screen and as mail header",
        allowed_file_types: "Only PNG, JPEG, and JPG files are allowed.",
      },
      logo_square: {
        label_text: "Logo Icon",
        drag_drop_text:
          "Drag and drop your file here, or <a href='#' class='browse-btn p-sm'>Browse</a>",
        help_text:
          "Uploaded icon will be displayed in sidebar when it was collapsed and as favicon",
        allowed_file_types: "Only PNG, JPEG, and JPG files are allowed.",
      },
    },
    action: {
      delete_logo_confirm: "Are you sure you want to delete logo?",
      delete_square_logo_confirm:
        "Are you sure you want to delete the logo icon?",
    },
  },
  header_module: {
    notification_section: {
      popup_heading: "Notifications",
      mark_all_read_text: "Mark all as read",
      view_all_text: "View All",
      no_data_text: "There are no notifications to display.",
    },
  },
  portal_configuration: {
    tile_editor: {
      heading: "Tile Editor",
      select_tile_text: "Select any one Tile to Edit",
      view_page_select_tile_text: "Select any one Tile to View",
      save_msg: "Tile Data saved successfully!",
      error_msg: "Please save your Tile Data.",
      min_age: {
        name: "Min Age Requirement for Self-Registration",
        placeholder: "Select",
      },
      title: {
        name: "Tile Primary Heading",
        placeholder: "Title",
      },
      description: {
        name: "Tile Subheading",
        placeholder: "Subheading",
      },
      status: {
        name: "Status",
        option_1: "Active",
        option_2: "Inactive",
      },
      bg_image: {
        name: "Upload an image for the tile background",
        view_page_name: "Background Image",
        upload_text: "Upload Your Image",
        filesize_text: "Maximum file size 200x300 Pixel.",
      },
    },
    talk_to_an_expert: {
      heading: "Talk to an Expert",
      expert_title: {
        name: "Page Title",
        placeholder: "Enter title",
      },
      expert_description: {
        name: "Page Description",
        placeholder: "Enter Page Description",
      },
      expert_image: {
        name: "Upload an image",
        view_page_name: "Image",
        upload_text: "Upload Your Image",
        filesize_text: "Maximum file size 200x300 Pixel.",
      },
      card1_title: "Card Detail (Card 1)",
      card2_title: "Card Detail (Card 2)",
      card: {
        title: {
          name: "Title",
          placeholder: "Enter title",
        },
        url: {
          name: "URL",
          placeholder: "Enter URL",
        },
        description: {
          name: "Description",
          placeholder: "Add a Description",
        },
        button_text: {
          name: "Button Text",
          placeholder: "Add Button Text",
          preview: "Button Preview",
        },
        image: {
          name: "Upload an image",
          view_page_name: "Image",
          upload_text: "Upload Your Image",
          filesize_text: "Maximum file size 200x300 Pixel.",
          allowed_file_types: "Only PNG, JPEG, and JPG files are allowed.",
        },
      },
    },
    movement_store: {
      heading: "Store CTA Links",
      store_title1: {
        name: "URL Title",
        placeholder: "Enter Title",
      },
      store_url1: {
        name: "URL",
        placeholder: "Enter URL",
      },
      store_title2: {
        name: "URL Title",
        placeholder: "Enter Title",
      },
      store_url2: {
        name: "URL",
        placeholder: "Enter URL",
      },
      store_title3: {
        name: "URL Title",
        placeholder: "Enter Title",
      },
      store_url3: {
        name: "URL",
        placeholder: "Enter URL",
      },
    },
    about_us: {
      heading: "About Us CTA Links",
      aboutus_title1: {
        name: "URL Title",
        placeholder: "Enter Title",
      },
      aboutus_url1: {
        name: "URL",
        placeholder: "Enter URL",
      },
      aboutus_title2: {
        name: "URL Title",
        placeholder: "Enter Title",
      },
      aboutus_url2: {
        name: "URL",
        placeholder: "Enter URL",
      },
      aboutus_title3: {
        name: "URL Title",
        placeholder: "Enter Title",
      },
      aboutus_url3: {
        name: "URL",
        placeholder: "Enter URL",
      },
      aboutus_title4: {
        name: "URL Title",
        placeholder: "Enter Title",
      },
      aboutus_url4: {
        name: "URL",
        placeholder: "Enter URL",
      },
    },
  },
  notification_center: {
    list: {
      page_title: "Notification Center List",
      page_heading: "All Notifications",
      no_record: "No Notifications Found",
    },
    show: {
      page_title: "Notification Center Show",
      page_heading: "Notification Detail",
    },
    breadcrumb: {
      parent_text: "Dashboard",
      list_text: "All Notifications",
      view_text: "View Notification",
    },
    action: {
      view: "View",
      delete: "Delete",
      delete_confirm: "Are you sure you want to delete this notification?",
    },
  },
  reporting: {
    name_required: "Please enter name!",
    save_query: "Save Query",
    query_name: "Query Name",
    query_name_plaeholder: "Enter Query Name",
    table_required: "Please select a table!",
    field_required: "Please select at least one field!",
    report_name_required: "Please enter report name!",
    table: "Table",
    select_table: "Select Table",
    join: "Join Data",
    related: "Related Data",
    fields: "Fields",
    run_query: "Run Query",
    report_name: "Report name",
    edit_report: "Edit Report",
    create_report: "Create Report",
    reporting: "Reporting",
    edit_a_report: "Edit a Report",
    create_a_report: "Create a Report",
    delete_report_confirm: "Are you sure you want to delete this report?",
    reports_management: "Reporting",
    build_a_report: "Build a Report",
    name: "Name",
    created_by: "Created by",
    created_at: "Created at",
    report_details: "Report Details",
    view_report: "View Report",
    edit_query: "Edit Query",
    export_csv: "Export CSV",
  },
  get_support: {
    page_title: "Support",
    page_heading: "Support",
    powered_by: "Form powered by",
    hep_portal: "HEP Portal",
    tech_support_email: "Tech Support Email",
    other_question: "Other Questions",
    section1_title: "User Guide",
    section1_description:
      "Everflex Plus was designed for Physical Therapy Practices and their therapists to streamline Home Exercise Programs (HEP’s), Remote Therapy Monitoring (RTM), Marketing, and Employer Services.  This guide was created to give an overview of Everflex Plus and all its components.",
    section2_title: "Tech Support (Software Related Issues)",
    section2_description:
      "Our tech support is available from 7:00 am to 4:00 pm PST, Monday to Friday, excluding major holidays. Any submissions made outside these hours will be addressed on the next business day.",
    section3_title: "Product Feedback Form",
    section4_title: "Exercise or Educational Content Request Form",
    support_email: "support@everflexplus.com",
  },
  sidebar_menus: {
    exit_tab_message:
      "Changes that you made may not be saved. Are you sure you want to continue?",
    dashboard_menu_text: "Dashboard",
    get_support_text: "Get Support",
    admin: {
      practice_menu_text: "Practices",
      clinic_management_menu_text: "Clinics",
      exercise_menu_text: "Exercises",
      education_menu_text: "Education",
      hep_template_menu_text: "HEP Templates",
      role_and_user_menu_text: "Users & Roles",
      reporting_menu_text: "Reporting",
      prevention_menu_text: "Prevention",
      billing_menu_text: "Billing",
      identify_my_injury_builder_menu_text: "Identify My Injury Builder",
      system_notification_menu_text: "System Notifications",
      account_setting_menu_text: "Account Settings",
      portal_copy_menu_text: "Portal Copy",
      ip_restriction_menu_text: "IP Restrictions",
      system_settings_menu_text: "System Settings",
    },
    practice: {
      practice_menu_text: "My Practice",
      clinic_management_menu_text: "Clinics",
      patient_management_menu_text: "Patient Management",
      new_patient_funnel_menu_text: "Patient Funnel",
      active_patients_menu_text: "Active Patients",
      prevention_management_menu_text: "Prevention",
      exercise_menu_text: "Exercises",
      education_menu_text: "Education",
      hep_template_menu_text: "HEP Templates",
      billing_menu_text: "My Bill",
      role_and_user_menu_text: "Users & Roles",
      reporting_menu_text: "Reporting",
      message_menu_text: "Messages",
      targeted_notification_menu_text: "Targeted Notifications",
      account_setting_menu_text: "Account Settings",
    },
  },
  account_setting: {
    show: {
      page_title: "Account Settings",
      page_heading: "Profile Details",
      edit_link_text: "Edit",
      first_name_label: "First Name",
      last_name_label: "Last Name",
      email_id_label: "Email/Username",
      phone_number_label: "Phone Number",
      change_password: {
        heading_text: "Change Password",
        current_password_field: {
          label_text: "Your Current Password",
          placeholder_text: "Enter Current Password",
        },
        new_password_field: {
          label_text: "New Password",
          placeholder_text: "Enter New Password",
        },
        confirm_password_field: {
          label_text: "Confirm Password",
          placeholder_text: "Enter Confirm Password",
        },
        submit_btn_text: "Submit",
      },
      notification_alerts: {
        heading_text: "Notifications & Alerts",
        thead: {
          description_text: "Descriptions",
          email_text: "Email",
          in_app_notification_text: "In-App Notification",
        },
        tbody: {
          ready_to_bill_title_text: "RTM Code Ready to Bill",
          ready_to_bill_description_text:
            "Alerts the Provider they can now bill",
          ready_to_bill_98975_title_text: "RTM Code 98975 Ready to Bill",
          ready_to_bill_98975_description_text:
            "Alerts the Provider they can now bill code 98975",
          ready_to_bill_98977_title_text: "RTM Code 98977 Ready to Bill",
          ready_to_bill_98977_description_text:
            "Alerts the Provider they can now bill code 98977",
          ready_to_bill_98980_title_text: "RTM Code 98980 Ready to Bill",
          ready_to_bill_98980_description_text:
            "Alerts the Provider they can now bill code 98980",
          ready_to_bill_98981_title_text: "RTM Code 98981 Ready to Bill",
          ready_to_bill_98981_description_text:
            "Alerts the Provider they can now bill code 98981",
          patient_engagement_drop_off_title_text: "Patient Engagement Drop Off",
          patient_engagement_drop_off_description_text:
            "Let's clinician know patient has not logged any activity in 48 hours (only for people we're tracking as EOC)",
          self_help_patient_engagement_drop_off_title_text:
            "Self-Help Patient Engagement Drop Off",
          self_help_patient_engagement_drop_off_description_text:
            "Let's practice know patient has not logged any activity in 3 days during a 21-day self-help recovery plan (only self-help plans)",
          message_received_title_text: "Message Received",
          message_received_description_text:
            "Badge on the Patient List next to the patient's name and option to be notified via email when alerts are activated.",
          patient_hep_plan_regression_title_text: "Patient HEP Plan Regression",
          patient_hep_plan_regression_description_text:
            "Badge on the Patient List next to the patient's name",
          patient_lack_of_progress_title_text:
            "Patient Lack of Progress in Self-Adjusting HEP for 96 Hours.",
          patient_lack_of_progress_description_text:
            "Badge on the Patient List next to the patient's name",
          patient_hep_strategy_too_hard_title_text:
            "Patient HEP Strategy Too Hard",
          patient_hep_strategy_too_hard_description_text:
            "Badge on the Patient List next to the patient's name",
          patient_hep_strategy_too_easy_title_text:
            "Patient HEP Strategy Too Easy",
          patient_hep_strategy_too_easy_description_text:
            "Badge on the Patient List next to the patient's name",
          patient_hep_exercise_as_too_easy_title_text:
            "Patient HEP Exercise as Too Easy",
          patient_hep_exercise_as_too_easy_description_text:
            "Badge on the Patient List next to the patient's name",
          patient_hep_exercise_as_too_hard_title_text:
            "Patient HEP Exercise as Too Hard",
          patient_hep_exercise_as_too_hard_description_text:
            "Badge on the Patient List next to the patient's name",
        },
      },
    },
    edit: {
      page_title: "Edit Settings",
      page_heading: "Edit Settings",
      cancel_link_text: "Cancel",
      save_button_text: "Save",
      first_name_field: {
        label: "First Name",
        placeholder: "Enter First Name",
      },
      last_name_field: {
        label: "Last Name",
        placeholder: "Enter Last Name",
      },
      email_id_field: {
        label: "Email/Username",
        placeholder: "Enter Email/Username",
      },
      phone_number_field: {
        label: "Phone Number",
        placeholder: "Enter Phone Number",
      },
      otp_modal: {
        otp_verification_title: "OTP VERIFICATION",
        otp_sub_heading: "We've sent a code to your mobile number.",
        otp_field_placeholder: "Enter OTP",
        cancel_button_text: "Cancel",
        verify_button_text: "Verify",
      },
      avatar: {
        modal_title: "Update Avatar",
        delete: "Delete",
        delete_confirm: "Are you sure you want to delete this avatar?",
      },
    },
    breadcrumb: {
      parent_text: "Account Setting",
      view_text: "Account Settings",
      edit_text: "Edit Settings",
    },
  },
  cropper_modal: {
    went_wrong: "Something went wrong please crop image again!",
    close_btn_text: "Close",
    update_btn_text: "Update",
    updating_btn_text: "Updating",
  },
  exercise_cropper_modal_title: "Crop Thumbnail",
  exercise_multi_cropper_modal_title: "Crop Images",
  practice_screen: {
    branding: {
      dropzone: {
        logo: {
          label_text: "Logo Image",
          drag_drop_text:
            "Drag and drop your file here, or <a href='#' class='browse-btn p-sm'>Browse</a>",
          help_text:
            "Uploaded icon will be displayed in sidebar, login screen and as mail header",
          allowed_file_types: "Only PNG, JPEG, and JPG files are allowed.",
        },
        logo_square: {
          label_text: "Logo Icon",
          drag_drop_text:
            "Drag and drop your file here, or <a href='#' class='browse-btn p-sm'>Browse</a>",
          help_text:
            "Uploaded icon will be displayed in sidebar when it was collapsed and as favicon",
          allowed_file_types: "Only PNG, JPEG, and JPG files are allowed.",
        },
      },
      action: {
        delete_logo_confirm: "Are you sure you want to delete logo?",
        delete_square_logo_confirm:
          "Are you sure you want to delete the logo icon?",
      },
    },
  },
  practice_tabs: {
    patient: "Patient",
    practice: "Practice",
    info: "Practice Information",
    branding: "Branding Defaults",
    portal_config: "Portal Configuration Defaults",
    reg_email: "Registration Email Defaults",
    portal_copy: "Portal Copy",
    misc_config: "Misc Configuration",
    prevention: "Prevention Management",
    practice_pricing: "Practice Pricing",
  },
  clinic_tabs: {
    patient: "Patient",
    clinic: "Clinic",
    info: "Clinic Information",
    misc: 'Clinic Configuration',
    branding: "Branding",
    portal_config: "Portal Configuration",
    reg_email: "Registration Email",
    portal_copy: "Portal Copy",
    prevention: "Prevention Management",
  },
  practice_info: {
    practice_details: "Practice Details",
    primary_contact_details: "Primary Contact Details",
    first_name_label: "First Name",
    first_name_placeholder: "Enter first name",
    last_name_label: "Last Name",
    last_name_placeholder: "Enter last name",
    primary_phone_label: "Primary Contact Phone Number",
    primary_phone_placeholder: "Enter primary contact phone number",
    primary_email_label: "Primary contact email",
    primary_email_placeholder: "Enter primary contact email",
    alter_phone_label: "Alternate Contact Phone Number",
    alter_phone_placeholder: "Enter alternate contact phone number",
    alter_email_label: "Alternate contact email",
    alter_email_placeholder: "Enter alternate contact email",
    mailing_address_title: "Mailing Address",
    physical_address_title: "Physical Address",
    address: "Address",
    line_1: "Line 1",
    line_2: "Line 2",
    city_label: "City",
    city_placeholder: "Enter city",
    state_label: "State",
    state_placeholder: "Select state",
    zip_label: "Zip Code",
    zip_placeholder: "Enter zip code",
    latitude_label: "Latitude",
    latitude_placeholder: "Enter Latitude",
    longitude_label: "Longitude",
    longitude_placeholder: "Enter Longitude",
    appointment_url_label: "Appointment URL",
    appointment_url_placeholder:
      "'Make an appointment' link for clinic maps tile",
    security_phrase_label: "Security Phrase",
    security_phrase_placeholder: "Enter security phrase",
    security_phrase_tooltip:
      "Please put a secret phrase here and do not share.  If you call Everflex Plus Tech Support, it will be required to assist you to ensure account security.",
    security_phrase_tooltip_2: "Example: Two Dogs Bark Loud",
  },
  clinic_misc_info: {
    auto_assigned_provider_label: "Self-Registered Patient Provider",
    auto_assigned_provider_placeholder: "Choose Provider",
    auto_assigned_provider_tooltip: "The provider you choose will be automatically assigned to any patient who self-registers for your clinic.",
    self_register_notification_email_label: "Self-Register Notification Email",
    self_register_notification_email_placeholder: "Enter email",
    self_register_notification_email_tooltip: "An email will be sent to this address any time a patient self registers for this clinic.",
  },
  practice_branding: {
    primary_label: "Primary Color",
    primary_tooltip: "Use Darker Color.",
    secondary_label: "Secondary Color",
    secondary_tooltip: "Use Light Color.",
  },
  please_fix_errors: "Please fix errors to continue!",
  edit_my_practice: "Edit my practice",
  practice_portal_copy: {
    add_about: "About",
    add_faq: "FAQ",
    about: "About",
    faq: "FAQ",
    insert_variable_text: "Insert Variables",
  },
  practice_email_tab: {
    subject_label: "Subject",
    subject_placeholder:
      "Welcome to the ##COMPANYNAME## Home Exercise Program (HEP)",
    email_content_label: "Registration Email Content",
    insert_variables: "INSERT VARIABLES",
  },
  practice_misc_tab: {
    auto_logout: "Auto Logout Time (Minute)",
    auto_logout_placeholder: "Select",
    auto_logout_tooltip:
      "3 Minutes is the recommended for optimal HIPAA compliance",
    is_demo: "Demo Practice",
    emr_integration_enabled: "Enable Prompt EMR Integration",
    emr_integration_enabled_tooltip:
      "Link patients and share data between Everflex and EMR systems in our network",
  },
  practice_pricing_tab: {
    provider_cost_label: "Per Provider Per Month License Fee",
    provider_cost_placeholder: "0.00",
    patient_cost_label: "Per Patient Overage Cost",
    patient_cost_placeholder: "0.00",
    patient_cost_tooltip: "Each Licensed Provider may have up to 200 Active Users per month on the Everflex Plus Platform. Active Users include Patients and Self-Registered individuals who log in and use the Platform within the month. Licensees with multiple providers may aggregate their Active User count.  Overages will be billed at this amount per extra user.",
    clinic_cost_label: "Per Clinic/Group Per Month License Fee",
    clinic_cost_placeholder: "0.00",
    text_cost_label: "Per Text Overage Cost",
    text_cost_placeholder: "0.00",
    text_cost_tooltip: "Each Licensed Provider may use up to 500 texts per month on the Everflex Plus Platform. Licensees with multiple providers may aggregate their text count.  Overages will be billed at this amount per extra text.",
    rtm_cost_label: "Per RTM Code Per Month License Fee",
    rtm_cost_placeholder: "0.00",
    use_rtm_label: "Use RTM Code Pricing",
    provider_minimum_label: "Minimum Per Provider Per Month Total",
    provider_minimum_placeholder: "0.00",
    patient_minimum_label: "Patient Overage Monthly Minimum",
    patient_minimum_placeholder: "0.00",
    clinic_minimum_label: "Minimum Per Clinic/Group Per Month Total",
    clinic_minimum_placeholder: "0.00",
    text_minimum_label: "Text Overage Monthly Minimum",
    text_minimum_placeholder: "0.00",
    rtm_max_per_provider_label: "Maximum Per Provider Cost",
    rtm_max_per_provider_placeholder: "0.00",
    rtm_number_providers_label: "# of Current Providers",
    fixed_pricing: {
      title: "Fixed Pricing",
      add_button_text: "Add Fixed Price",
      name_label: "Name",
      monthly_cost: "Monthly Fee",
      name_placeholder: "Enter fixed price name",
      monthly_cost_placeholder: "00.00",
      delete: "Delete",
    },
  },
  clinic: {
    practice_filter: "Filter by Practice",
    create_clinic: "Create Clinic",
    clinic_management: "Clinics",
    edit_clinic: "Edit Clinic",
    archive_clinic_confirm: "Are you sure you want to archive this clinic?",
    unarchive_clinic_confirm: "Are you sure you want to unarchive this clinic?",
    create_clinic_details: "Create Clinic Details",
    clinic_details: "Clinic Details",
  },
  clinic_table_header: {
    id: "ID",
    logo: "Clinic Logo",
    name: "Clinic name",
    email: "Email",
    phone: "Clinic Phone",
    vanity_url: "Vanity URL",
    address: "Clinic Address",
    actions: "Actions",
  },
  practice_list: {
    label: "Practice",
    placeholder: "Select Practice",
  },
  clinic_name: {
    label: "Clinic name",
    placeholder: "##COMPANYNAME##",
  },
  clinic_email: {
    label: "Clinic Admin Email",
    placeholder: "example@mail.com",
  },
  clinic_phone: {
    label: "Phone Number",
    placeholder: "Enter Number",
  },
  clinic_dob: {
    label: "Date Of Birth",
    placeholder: "MM-DD-YYYY",
  },
  clinic_url: {
    label: "Vanity URL",
    placeholder: "Enter Vanity URL",
    hint_text:
      "Per licensing agreement, please only use your legal trade names",
  },
  patient: {
    create_patient: "Create Patient",
    patient_management: "Patient Management",
    edit_Patient: "Edit Patient",
    archive_patient_confirm: "Are you sure you want to archive this patient?",
    unarchive_patient_confirm:
      "Are you sure you want to unarchive this patient?",
    create_patient_details: "Create Patient Details",
    patient_details: "Patient Details",
    active_patient_title: "Care Plans",
    active_patient_sub_title: "Patient Demographics",
    comm_not_met_tooltip: (phone, link) => `<p>You still need to complete an interactive communication with this patient to fulfill code 98980.</p> <p>Patient's Phone: ${phone}</p> <p>Once the call is complete, click <a href='${link}'>here</a> to log the call.</p>`,
    status: {
      archived_text: "Archived",
      invited_text: "Invited",
      email_not_verified_text: "Email not verified",
      phone_not_verified_text: "Phone not verified",
      registered_text: "Registered",
      no_email_text: "No Email",
    },
    qr_code_verification_title: 'Verification Link for {name}',
    qr_code_verification_subtitle: 'Scan QR code to register',
  },
  patient_table_header: {
    id: "ID",
    first_name: "First name",
    last_name: "Last name",
    phone: "Phone number",
    email: "Email",
    dob: "Date of Birth",
    clinic: "Clinic",
    provider: "Provider",
    status: "Status",
    actions: "Actions",
    last_login: "Last Login",
    patient_name: "Patient Name",
    indicators: "Indicators",
    lable_98977: "98977",
    lable_98980: "98980",
    lable_98981: "98981",
  },
  new_patient_funnel: {
    no_of_records: "Number of Records",
    send_notification: "Send Notification",
    new_patient_funnel_management: "Patient Funnel",
    edit_Patient: "Edit Patient",
    archive_patient_confirm: "Are you sure you want to archive this patient?",
    unarchive_patient_confirm:
      "Are you sure you want to unarchive this patient?",
    create_patient_details: "Create Patient Details",
    patient_details: "Patient Details",
    active_patient_title: "Care Plans",
    active_patient_sub_title: "Patient Demographics",
    status: {
      archived_text: "Archived",
      invited_text: "Invited",
      email_not_verified_text: "Email not verified",
      registered_text: "Registered",
    },
    select_one_patient: "Please select a patient to send notification",
    outreach_log: {
      title: "Outreach Log",
      outreach_log_title: "Outreach",
      new_outreach_log: "New Outreach Log",
      added_by: "Added By",
      no_records_found: "No Records Found",
      select_outreach_type: "Select Outreach Type",
      outreach_note: "Outreach Note",
      enter_note: "Enter Note...",
    },
  },
  new_patient_funnel_table_header: {
    id: "ID",
    first_name: "First name",
    last_name: "Last name",
    phone: "Phone number",
    email: "Email",
    registration_date: "Registration Date",
    last_outreach_date: "Last Outreach Date",
    actions: "Actions",
  },
  patient_fields: {
    clinic_label: "Clinic",
    clinic_placeholder: "Select Patient Clinic",
    provider_label: "Provider",
    provider_placeholder: "Select Patient Provider",
    preferred_name_label: "Preferred name",
    preferred_name_placeholder: "Enter Preferred name",
    dob_label: "Date of birth",
    dob_placeholder: "Enter date of birth",
    language_label: "Preferred Language",
    language_placeholder: "Select Preferred Language",
    gender_label: "Gender",
    gender_placeholder: "Select Gender",

    emr_patient_id_label: "EMR Patient ID",
    emr_patient_id_placeholder: "-",
    other_label: "Other",
    other_placeholder: "",

    first_name_label: "First Name",
    first_name_placeholder: "Enter first name",
    last_name_label: "Last Name",
    last_name_placeholder: "Enter last name",
    phone_label: "Phone Number",
    phone_placeholder: "Enter phone number",
    email_label: "Patient Email Address",
    email_placeholder: "Enter patient email",
    email_or_phone_required: "Email and/or Phone Required",
    address: "Address",
    line_1: "Line 1",
    line_2: "Line 2",
    city_label: "City",
    city_placeholder: "Enter city",
    state_label: "State",
    state_placeholder: "Select state",
    zip_label: "Zip Code",
    zip_placeholder: "Enter zip code",
    mailing_address_title: "Patient Address",
    billing_address_title: "Billing Address",
    registration_date_label: "Registration Date",
    workplace_plan_label: "Last Self-Assigned Workplace Prevention Plan",
    sports_plan_label: "Last Self-Assigned Sports Prevention Plan",
    generalized_plan_label: "Last Self-Assigned Generalized Prevention Plan",
    specialty_plan_label: "Last Self-Assigned Specialty Prevention Plan",
    outreach_status_label: "Status",


    rtm_tag_label: "RTM Tagged",
    view_label: "Patient Filter",
  },
  active_patients: "Active Patients",
  emr_patient_choice_title: "Choose a Patient to Link",
  emr_patient_account_details: "EMR account to be linked: ",
  active_patients_module: {
    rtm_text: "RTM",
    show_more: "Show More",
    show_less: "Show Less",
    emr_modals: {
      update_notification: {
        title: "EMR Account Updates",
        subtitle:
          "We found an update to this Patient's information in their EMR account. Would you like to update it in Everflex?",
      },
      confirm_notification: {
        title: "Is This the Same Patient?",
        subtitle:
          "We found an existing record with the same information, is this the same patient?",
        yes_text: "Yes",
        no_text: "No",
        ok_text: "Ok",
        email_exists_title: "Please Choose Another Email Address",
        email_exists_subtitle:
          "There is another patient in this practice with the same email address, so we cannot create a new account with this information. Please choose a different email address in the EMR account to link with Everflex.",
        linkage_choice_title: "How would you like to link this account?",
        linkage_choice_subtitle: "To connect the EMR account to a Patient in Everflex Plus, choose one of the following options:",
        link_to_existing_text: "Link to existing patient",
        create_new_text: "Create new patient",
      },
      go_to_edit: "Edit Patient",
    },
    manage_hep: {
      assign_new_hep: "Assign a New HEP",
      assign_hep_to_patient: "Assign HEP to Patient",
      create_hep_from_template: "Create HEP from Template",
      create_new_hep: "Create New HEP",
      edit_hep: "Edit HEP",
      active_hep: "Active HEP",
      assign_hep: "Assign HEP",
      patient_details: "Patient Details",
      hep_details: "HEP Details",
      hep_template_list: {
        title: "HEP Template List",
        table: {
          sort: "ID",
          hep_name: "HEP Template Name",
          rtm_status: "RTM Status",
          level: "Level",
          created_by_name: "Created By",
          actions: "Actions",
        },
      },
      create_update_hep: {
        save_print_btn_tooltip: "Save & Print",
        save_create_template_btn_tooltip: "Save and Create Template",
        hep_template_list: {
          title: "HEP Template List",
          table: {
            sort: "ID",
            hep_name: "HEP Template Name",
            description: "Description",
            level: "Level",
            created_by_name: "Created By",
            actions: "Actions",
          },
        },
        save_as_template_modal: {
          title: "HEP Template Name",
          description:
            "Make sure there is no PHI in this HEP before saving as a Template. Please create a name for the HEP Template you are about to Save.",
          cancel_btn: "Cancel",
          save_btn: "Save",
          overwright_btn: "Overwright Template",
          template_name: {
            label: "Template Name",
            placeholder: "Enter Template Name",
          },
        },
      },
      rtm_contact_logs: {
        auto_logged: "Auto-Logged",
        title: "RTM Contact Logs",
        add_log_modal_title: "RTM Contact Log",
        add_log_btn_text: "Add RTM Contact Log",
        export_log_btn_text: "Export CSV",
        contact_date: {
          label: "Contact Date",
          placeholder: "MM/DD/YYYY",
        },
        duration: {
          label: "Duration (Minutes)",
          placeholder: "00",
        },
        interaction_types: {
          label: "Type Of Interaction",
          placeholder: "Select Interaction",
        },
        note: {
          label: "Add Note",
          placeholder: "Enter Details",
        },
        confirm_modal: {
          title: "Are you sure you want to save?",
          description:
            "This log entry cannot be removed or updated once it has been created.",
          no_text: "No",
          yes_text: "Yes",
        },
        sort: {
          new_to_old: "Newest to Oldest",
          old_to_new: "Oldest to Newest",
        },
      },
      rtm_code: {
        code: "Code",
        minutes: "Minutes",
        marked_completed: "Marked as completed",
        pta_assist: "PTA Assist",
        copy_to_emr: "Copy To EMR",
        copy_statement: "Copy Note",
        recopy_statement: "Recopy Note",
        copied: "Copied",
        recopy: "Recopy",
        copy_rtm_token: "Copy RTM Token",
        copy_all_unbilled: "Copy Token for All Unbilled",
        copy_all_unbilled_98977: "Copy Token for All Unbilled 98977 Code",
        copy_all_unbilled_98980: "Copy Token for All Unbilled 98980 Code",
        copy_all_unbilled_98981: "Copy Token for All Unbilled 98981 Code",
        copy_all_unbilled_98975: "Copy Token for All Unbilled 98975 Code",
        ready_to_bill: "Ready To Bill",
        billed: "Billed",
        not_billed: "Not Billed",
        mark_as_billed: "Mark as Billed",
        mark_as_unbilled: "Reset",
        billing_status_changed: "Billing status successfully changed.",
        emr_copied: "EMR data was Copied",
        token_copied: "RTM Token was copied",
        unbilled_copied: "EMR token was copied",
        emr_copy_error: "Something went wrong while copying EMR data.",
        code_98981: {
          number: "98981",
          tooltip:
            "Remote therapeutic monitoring treatment, physician/other qualified health care professional time in a calendar month requiring at least one interactive communication with the patient/caregiver during the calendar month each additional 20 minutes.",
          sub_text1: "Ineligible",
          sub_text2: "Complete 98980 First",
          check1_text: "Completed 98980.",
          check2_text:
            "Logged Additional 20 Minutes of Monitoring (Reviewing, monitoring, discussing with patient, changing, etc) within this calendar month.",
        },
        reminder_title: "Reminder",
        first_98977_warning:
          "To keep dashboard reporting accurate with billing timelines, it is recommended that you bill this code on the date it was triggered in the system of ",
        second_98977_warning:
          "Most payers will deny claims for this code until 30 full days have passed since the last time you billed 98977 for this patient. We recommend billing it 31 days or more after the date you billed it in your EMR to ensure the greatest chance of claim approval.",
        dont_show_warning: `Don't show this again`,
        close_warning: "Okay",
      },
      rtm_code_98980: {
        completed_text: "Completed",
        code_number: "98980",
        tooltip_text:
          "Remote therapeutic monitoring treatment, physician/other qualified health care professional time in a calendar month requiring at least one interactive communication with the patient/caregiver during the calendar month; first 20 minutes",
        requirement_1_text:
          "Logged 20 Minutes of Monitoring (Reviewing, monitoring, discussing with patient, changing, etc...) within this calendar month.",
        requirement_2_text:
          "Performed and Logged at Least 1 Interactive Communication with the Patient per Calendar Month.",
      },
    },
    hep_detail: {
      tabs: {
        hep_tab_text: "HEP",
        rtm_code_tab_text: "RTM CODE",
        rtm_logs_tab_text: "RTM LOGS",
      },
      resend_link: "Resend Link",
      edit: "Edit",
      copy_hep_data: "Copy HEP Data",
      copy_hep_token: "Copy Prompt HEP Token",
      copy: "Copy",
      soap_notes_template_text: "SOAP Notes Template",
    },
    hep_list: {
      hep_tab: {
        active_text: "Active HEP",
        inactive_text: "Inactive HEP",
        home_programme_text: "Independent Home Programs",
        self_assign_text: "Self-Assigned",
      },
      start_date: "Start Date",
      end_date: "End Date",
      view_details_text: "View Details",
      show_more_link_text: "Show more",
      deactivate_hep_tooltip: "Deactivate HEP",
      activate_hep_tooltip: "Convert to an Active HEP",
      duplicate_hep_tooltip: "Duplicate HEP",
      confirm_hep_inactive: {
        title:
          "Are you sure you want to close this HEP/active episode of care?",
        title2: "Are you sure you want to close this HEP?",
        sub_title: "Warning: This cannot be undone.",
      },
      confirm_hep_active: {
        title:
          "Are you sure you want to activate this HEP/active episode of care?",
        sub_title: "Warning: This cannot be undone.",
      },
      confirm_hep_inactive_again: {
        title:
          "Would you like to convert this plan into an Independent Home Program for your patient?",
        sub_title:
          "Warning: It will Inactivate this HEP and create a new Independent Home Program .",
      },
      confirm_duplicate: {
        title: "Are you sure you want to duplicate this HEP?",
        sub_title:
          "Warning: It will create a new HEP in the “Active HEP” section for",
      },
    },
    provider_notes: {
      title: "Provider Notes List",
      add_btn_text: "Create Provider Note",
      add_note_modal: {
        page_title: "Provider Notes Details",
        description: {
          label: "Notes Description",
          placeholder: "Enter Notes Description",
        },
        title: {
          label: "Title",
          placeholder: "Enter Provider Notes Title",
        },
      },
    },
    self_help_log: {
      thead: {
        body_region_text: "Body Region",
        question_text: "Question",
        user_answer_text: "User Answer",
        date_text: "Date",
        time_text: "Time",
      },
      no_record: "No Record Found!",
    },
  },
  prevention_plan: {
    heading: "Prevention",
    create_plans: "Create Plan",
    update_plans: "Update Plan",
    view_plan: "View Plan",
    workplace: "Workplace Plans",
    sport: "Sport Plans",
    speciality: "Speciality Plans",
    generalized: "Generalized Plans",
    add_plan: "Add Plan",
    save_plan: "Save Plan",
    add_plan_msg: "Plan has been added successfully.",
    save_plan_msg: "Plan has been updated successfully.",
    delete_plan_confirm:
      "Are you sure you want to delete this prevention plan?",
    create_update_page: {
      create_plan: "Create Plan",
      create_new_plan: "Create New Plan",
      update_plan: "Update Plan",
      plan_name: {
        label: "Plan Name",
        placeholder: "Enter Plan Name",
      },
      plan_thumb: {
        label: "Upload an Image",
        view_page_label: "Image",
        placeholder:
          "Drag and drop your file here, or <a href='#' class='browse-btn p-sm'>Browse</a>",
      },
      select_hep: "Select HEP Templates",
      selected_hep: "Selected HEP Template",
      thumbnail_modal_title: "Crop Thumbnail",
      exercise_count_text: "Exercises",
      education_count_text: "Education Video",
    },
  },
  not_applicable: "N/A",
  no_record_found: "No Records Found",
  error_page: {
    go_back_text: "Go Back Home",
    403: {
      title: "403: Forbidden",
      description: "Sorry, you are forbidden from accessing this page.",
    },
    404: {
      title: "404: Page Not Found",
      description: "Sorry, the page you are looking for could not be found.",
    },
    500: {
      title: "500: Server Error",
      description: "Whoops, something went wrong on our servers.",
    },
    503: {
      title: "503: Service Unavailable",
      description:
        "Sorry, we are doing some maintenance. Please check back soon.",
    },
  },
  hep_data_copied_to_clipboard:
    "HEP data has been copied successfully to the clipboard.",
  hep_token_copied_to_clipboard:
    "HEP token has been successfully copied to the clipboard",
  soap_note_copied_to_clipboard:
    "SOAP note has been copied successfully to the clipboard.",
  general_error: "Something went wrong. Try again.",
  messages: {
    title: "Messages",
    new_message: "New Message",
    welcome: "Welcome",
    you_are_signed_in_as: "You are signed in as",
    hi: "Hi",
    you_are_disconnected: "You are currently disconnected from the internet.",
    patient: "Patient",
    could_not_send_msg: "Could not send the message.",
    some_files_removed: "Some files above 100 MB size were removed.",
    say_hello: "Say Hello",
    hello_text: "Hello!",
    start_conversation: "Start your new conversation",
    send: "Send",
    textbox_placeholder: "Start typing...",
    search_placeholder: "Search...",
    new_msg_search_placeholder: "Search...",
    ai_translation_disclaimer: "Translation Disclaimer",
    ai_translation_disclaimer_text:
      "Please be aware that our translations utilize AI software with an accuracy of about 98%. It is the responsibility of you, the Provider, to ensure that all written instructions and communications are clear and safe for the Patient both pre and post translation. To prevent potential issues, please use straightforward language and refrain from using slang.",
    i_understand_and_agree: "I ACKNOWLEDGE AND ACCEPT THE RESPONSIBILITY",
  },
  header_messages: {
    title: "Messages",
    view_all: "VIEW ALL",
    no_messages: "No messages to show Yet",
  },
  rtm_tracking: {
    rtm_tracking_text: "RTM Tracking",
    flash_card: {
      heading_text: "Warning!",
      sub_heading_text:
        "Once RTM is turned on it cannot be turned off. Are you sure you want to turn it on?",
    },
  },
  password_requirement_text:
    "Password must contain a minimum of 8 characters, containing a mix of uppercase and lowercase letters, numbers, and symbols.",
  injury_builder: {
    title: "Identify My Injury Builder",
    heading: "My Injury Builder",
    delete_body_part_confirm: "Are you sure you want to delete this body part?",
    build_an_injury: "Build an injury",
    body_part: "Body part",
    name: "Name",
    created_by: "Created by",
    created_at: "Created at",
    create_title: "Injury Builder",
    saved_successfully: "Saved successfully!",
    errors: {
      add_nodes: "Please add nodes.",
      connect_nodes: "Please connect nodes.",
      one_starting_node: "Cannot have more than one starting node.",
      unlinked_nodes_removed: "Some unlinked nodes have been removed.",
      question_min_2_child: "Every question node requires at least 2 children.",
      question_value_required: "Every question node requires a value.",
      question_valid_video_url_required:
        "Question URL should be a valid Wistia video URL.",
      option_max_1_child: "Every option node must have at most one child.",
      option_value_required: "Every option node requires value.",
      starting_node_one_child: "Starting node can only have one child.",
    },
    question: "Question",
    option: "Option",
    hep: "Hep",
    generalized_plan: "Generalized Plans",
    option_placeholder: "Option",
    question_placeholder: "Question",
    question_video_url_placeholder: "Video URL",
    select_hep_search_placeholder: "Search...",
  },
  portal_copy: {
    list_page: {
      page_title: "Portal Copy",
      page_heading: "Portal Copy",
      no_record: "No Record Found",
      table: {
        heading: {
          id_text: "ID",
          title_text: "Title",
          description_text: "Description",
          action_text: "Action",
        },
      },
    },
    show_page: {
      page_title: "View Portal Copy",
      page_heading: "View Portal CMS Info",
      edit_btn_text: "Edit",
    },
    edit_page: {
      page_title: "Edit Portal Copy",
      page_heading: "Edit Portal CMS Info",
      cancel_btn_text: "Cancel",
      save_btn_text: "Save",
      insert_variable_text: "Insert Variables",
    },
  },
  admin_billing: {
    approve_btn: "Approve & Generate",
    save_btn: "Save",
    download_btn: "Download",
    paid_btn: "Mark as Paid",
    list_page: {
      page_title: "Billing Management",
      page_heading: "Billing Management",
      no_record: "No Record Found",
      save_btn: "Save",
      change_status_modal: {
        reason_placeholde: "write here...",
        cancel_btn: "Cancel",
        proceed_btn: "Proceed",
      },
      cancel_modal: {
        title: "Cancellation Reason",
        sub_title: "Write a reason for canceling invoice.",
      },
      in_dispute_modal: {
        title: "In Dispute Reason",
        sub_title: "Write a reason for disputing invoice.",
      },
      refund_modal: {
        title: "Refund Reason",
        sub_title: "Write a reason for refunding invoice.",
      },
      mark_paid_modal: {
        title: "Payment Option",
        sub_title: "Select payment option that practice used",
        cancel_btn: "Cancel",
        paid_btn: "Mark As Paid",
        check_number_placeholde: "Enter Check Number",
      },
      table: {
        heading: {
          id_text: "ID",
          practice_logo_text: "Practice Logo",
          practice_name_text: "Practice Name",
          amount_text: "Amount",
          due_date_text: "Due Date",
          provider_text: "Providers",
          status_text: "Status",
          action_text: "Action",
        },
      },
      action_dropdown: {
        view: "View",
        edit: "Edit",
        cancel: "Cancel",
        download: "Download",
        mark_paid: "Mark as Paid",
        in_dispute: "In Dispute",
        refunded: "Refund",
      },
      filter_sidebar: {
        status: "Status",
        provider_range: "No. of Providers",
        amount_range: {
          title: "Amount Range",
          start_amount_placeholder: "$0",
          end_amount_placeholder: "$400",
        },
        date_range: "Date Range",
      },
      default_cost_form: {
        number_providers_label: "# of Current Providers",
        provider_cost: {
          label: "Per Provider Per Month License Fee Default",
          placeholder: "00.00",
        },
        rtm_cost: {
          label: "Per RTM Code Per Month License Fee Default",
          placeholder: "00.00",
        },
        patient_cost: {
          label: "Per Patient Overage Cost Default",
          placeholder: "00.00",
        },
        clinic_cost: {
          label: "Per Clinic/Group Per Month License Fee Default",
          placeholder: "00.00",
        },
        text_cost: {
          label: "Per Text Overage Cost Default",
          placeholder: "00.00",
        },
        rtm_maximum: {
          label: "Maximum Per Provider Cost",
          placeholder: "00.00",
        },
        provider_minimum: {
          label: "Minimum Per Provider Per Month Total",
          placeholder: "00.00",
        },
        patient_minimum: {
          label: "Active Patient Overage Monthly Minimum",
          placeholder: "00.00",
        },
        clinic_minimum: {
          label: "Minimum Per Clinic/Group Per Month Total",
          placeholder: "00.00",
        },
        text_minimum: {
          label: "Text Overage Monthly Minimum",
          placeholder: "00.00",
        },
      },
    },
    view_edit_page: {
      view_page_title: "View Bill",
      edit_page_title: "Edit Bill",
      cancel_btn: "Cancel Invoice",
      practice_details: "Practice Details",
      invoice_number: "Invoice Number",
      practice_name: "Practice Name",
      status: "Status",
      issue_date: "Bill Issue Date",
      due_date: "Bill Due Date",
      reason: "Reason",
      payment_date: "Payment Received Date",
      payment_mode: "Payment Mode",
      check_number: "Check Number",
      item_details: "Item Details",
      invoice_view_title: "Invoice",
      invoice_date: "Invoice Date",
      invoice_view_due_date: "Due Date",
      paid_date: "Paid Date",
      bill_to: "Billed To",
      from: "From",

      table: {
        description: "Description",
        sub_text: "Sub Text",
        qty: "Quantity",
        monthly_min: "Monthly Min.",
        price: "Price",
        amount: "Amount",
      },
      sub_total: "Sub Total",
      discount: "Discount",
      add_discount_text: "Add",
      remove_discount_text: "Remove",
      total: "Total",
      amount_due: "Amount Due",
      total_amount_due: "Total Amount Due",
      discount_modal: {
        title: "Add Discount",
        sub_title: "Add discount amount or percentage",
        add_btn: "Add",
        cancel_btn: "Cancel",
        percentage_exceed_error:
          "Oops! The discount percentage cannot exceed 100%. Please enter a valid discount.",
      },
    },
  },

  practice_billing: {
    download_btn: "Download",
    pay_btn: "Pay",
    list_page: {
      page_title: "My Billing Statements",
      page_heading: "My Billing Statements",
      no_record: "No Record Found",
      save_btn: "Save",
      table: {
        heading: {
          id_text: "ID",
          date: "Date",
          invoice: "Invoice",
          invoice_total: "Invoice Total",
          payment_mode: "Payment Mode",
          status_text: "Status",
          action_text: "Action",
        },
      },
      action_dropdown: {
        view: "View",
        pay_invoice: "Pay Invoice",
        download: "Download",
      },
    },
    pay_bill_page: {
      page_title: "Pay Bill",
      card_detail: "Card Details",
      auto_pay: "Auto Pay",
      edit_btn: "Edit",
      delete_btn: "Delete",
      payment_confirm_msg: "Are you sure you want to procced to make payment?",
      card_delete_confirm_msg: "Are you sure you want to delete this card?",
    },
    edit_card_modal: {
      title: "Edit Card",
      exp_month_label: "Exp. Month",
      exp_year_label: "Exp. Year",
      save_btn: "Update",
      cancel_btn: "Cancel",
    },
  },
  status: {
    archived_text: "Archived",
    invited_text: "Invited",
    email_not_verified_text: "Email not verified",
    registered_text: "Registered",
    blocked: "Blocked",
  },
  unblock_account: "Unblock Account",
  confirm_unblock_account: "Are you sure you want to unblock this account?",
  confirm_unblock_patient: "Are you sure you want to unblock this patient?",
  confirm_unblock_ip: "Are you sure you want to unblock this IP Address?",
  auth_management: "Auth Management",
  patient_com_pref: {
    page_heading_text: "Communication Preferences",
    table_headings: {
      setting_text: "Setting",
      opt_in_text: "Opt-In",
      opt_out_text: "Opt-Out",
    },
    table_body: {
      email_notification_text: "Email Notifications",
      sms_notification_text: "Text (SMS) Notifications",
    },
    submit_btn_text: "Submit",
  },
  user_info: "User Information",
  update_password: {
    heading: "Update Password",
    new_password_label: "New Password",
    new_password_placeholder: "Enter New Password",
    confirm_password_label: "Confirm New Password",
    confirm_password_placeholder: "Confirm New Password",
  },
  emulate_account: "Emulate Account",
  confirm_emulate_account: "Are you sure you want to emulate this account?",
  confirm_link_patient: "Are you sure you would like to link this patient to this EMR account?",
  confirm_unlink_emr: "Are you sure you would like to unlink this patient from the EMR account?",
  link_patient: "Link Patient",
  set_new_password: {
    heading: "SET YOUR NEW PASSWORD",
    sub_heading:
      "For enhanced security, please enter your current password provided by the administrator and then set your new password.",
    current_password_label: "Current Password",
    current_password_placeholder: "Enter Current Password",
    new_password_label: "New Password",
    new_password_placeholder: "Enter New Password",
    confirm_password_label: "Confirm New Password",
    confirm_password_placeholder: "Confirm New Password",
    save_btn_text: "Save Password",
  },
  print_area: {
    hep_title_text: "Your Home Exercise Program (HEP)",
    your_provider_text: "Your Provider is:",
    title_description_text:
      "We HIGHLY recommend accessing your exercises online. Our HIPAA-compliant software allows your provider to track your progress remotely and make adjustments as needed for a targeted approach to your physical therapy which yields better results.",
    access_hep_text:
      "To access your HEP online please enter the following URL into your browser",
    description_text: "HEP Description/Instructions:",
    your_hep_text: "Your HEP",
    level_text: "Level",
    exercise_completed_text: "Exercise Completed",
    starting_position_text: "Starting Position:",
    what_you_should_feel_text: "What You Should Feel:",
    hold_time_text: "Hold Time:",
    second_text: "Sec.",
    day_per_week_text: "Day Per Week:",
    equipment_text: "Equipment:",
    sets_text: "Sets:",
    reps_text: "Reps:",
    weight_text: "Weight (In pounds):",
    time_per_day_text: "Time Per Day:",
    by: "By",
    days: "Days.",
    sets: "Sets.",
    reps: "Reps.",
    lbs: "Lbs.",
    times: "Times",
    not_applicable: "N/A",
  },
  system_prompt_defaults: {
    agree: 'I Agree',
    yes: 'Yes',
    no: 'No',
    choose_date: 'Choose Date',
    choose_time: 'Choose Time',
    enter: 'Enter',
  },
  indicators_tooltip: {
    title: "Provider Indicators",
    main_text: "Click the icon to go directly to the location where the Indicator was triggered. Designed as a daily work checklist, clicking the Indicator will take you to the relevant section. For example, clicking an unread message Indicator will bring you to the message center and the unread message. Once the message is read, the Indicator will disappear.",
    sub_text: "See more about how Indicators work in the knowledge-base",
    link_text: "HERE",
    rtm_code: "RTM Code Ready To Bill",
    unread_messages: "Unread Messages",
    exercise_too_hard: "Patient has Marked Exercise as Too Hard in HEP",
    exercise_too_easy: "Patient has Marked Exercise as Too Easy in HEP",
    strategy_too_hard: "Patient HEP Strategy Too Hard",
    strategy_too_easy: "Patient HEP Strategy Too Easy",
    engagement_drop_off: "Engagement Drop-Off",
    self_help_drop_off: "Self-Help Engagement Drop-Off",
  },

};
